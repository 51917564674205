import React, { useState } from "react";
import { Logo } from "../../core/assets/resources/images";
import { classNames } from "../../core/utils/className";

export const Header = () => {
const [showMobileMenu,setMobileMenu] = useState(false)
    const handleCollapseMenu = () => {
        setMobileMenu(!showMobileMenu)
    }

    const scrollToSection = (e:any) => {
        e.preventDefault(); 
        const targetId = e.target.getAttribute("href"); 
        document.querySelector(targetId)?.scrollIntoView({ behavior: "smooth" });
      };
  return (
    <section>
      <div className="flex justify-between relative max-w-7xl w-full  flex-wrap  items-center px-4 md:px-6 mx-auto py-5">
        <nav className="py-2.5 w-full">
          <div className="flex flex-wrap items-end justify-between  px-4 mx-auto">
            <a href="#" className="flex items-center">
              <img
                src={Logo}
                className="h-[78.65px] w-[200px]"
                alt="Landwind Logo"
              />
            </a>
            <div className="flex items-center lg:order-2 lg:hidden">
              <div className="hidden mt-2 mr-4 sm:inline-block">
                <span></span>
              </div>

              {/* <a
                href="https://themesberg.com/product/tailwind-css/landing-page"
                className="text-black bg-gray-200 hidden md:block font-light rounded-full p-4 text-sm px-4 lg:px-5"
              >
                Get Started
              </a> */}
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-full p-4 lg:hidden hover:bg-gray-200   dark:text-black dark:hover:bg-gray-200 "
                aria-controls="mobile-menu-2"
                aria-expanded="true"
                onClick={handleCollapseMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={classNames (showMobileMenu ? 'items-center justify-between w-full lg:flex lg:w-auto lg:order-1' : "hidden",'items-center justify-between w-full lg:flex lg:w-auto lg:order-1') }
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-light lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                <a href="#our-network" className="text-[14px]  hover:bg-gray-200 hover:font-bold hover:rounded-full hover:p-4" aria-current="page" onClick={scrollToSection}>Our Network</a>

                </li>
                <li>
                <a href="#features" className="text-[14px] hover:bg-gray-200 hover:font-bold hover:rounded-full hover:p-4" aria-current="page" onClick={scrollToSection}>The Instant Credit Advantage </a>

                </li>
                {/* <li>
                  <a href="#" className="text-[14px]">
                    Testimonials
                  </a>
                </li> */}
                {/* <a
                href="https://themesberg.com/product/tailwind-css/landing-page"
                className="text-black bg-gray-200 block md:hidden w-[120px] ml-[-5px] text-center font-light rounded-full p-4 text-sm px-4 lg:px-5"
              >
                Get Started
              </a> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};
