import React from "react";

export const Footer = () => {
  return (
    <section className=" w-full text-white bg-[#4D4D4D]">
      <div className="grid grid-cols-1 sm:grid-cols-2 mt-12 text-[13px] mx-auto max-w-7xl w-full  p-12 md:grid-cols-2 lg:grid-cols-2">
        <div>
          <p className="my-3 font-bold">Address</p>

          <p>The Voyager,</p>
          <p>3 Bearded Street </p>
          <p>North Legon- Accra, Ghana</p>
        </div>
        <div>
          <div className="inline-block">
            <div className="font-bold">Email</div>
            <a href="mailto:trade@nsano.com">trade@nsano.com</a>
          </div>
          <div className="mt-4">
            <div className="font-bold">Phone</div>
            <div>+233 531 040 423</div>
            <div>+233 302 90 9018</div>
          </div>
        </div>
        {/* <div>
          <p>Legal Links</p>
          <p>Terms of Service</p>
          <p>Privacy Policy</p>
        </div> */}
        {/* <div>Follow Us.</div> */}
      </div>
    </section>
  );
};
