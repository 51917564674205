import React from "react";
import { Header } from "../components/header";
import { Home } from "./home";
import { Footer } from "../components/footer";
import { Outlet } from "react-router-dom";

export const Layout = ({ children }: any) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
