import {
  Benin,
  Botswana,
  Burkina,
  Cameroon,
  Chad,
  DRC,
  Egypt,
  Equatorial,
  Gabon,
  Gambia,
  Ghana,
  Guinea,
  Kenya,
  Liberia,
  Malawi,
  Mali,
  Morocco,
  Niger,
  Nigeria,
  RCA,
  Rwanda,
  Senegal,
  Somali,
  SouthAfrica,
  Southsudan,
  Tanzania,
  Uganda,
  Uk,
  Zambia,
  Zimbabwe,
  Cote,
  Country1,
  Burundi,
  Sierre
} from "../../../core/assets/resources/images/Flags";

export const CountryFlags = [
  {
    name: "Benin",
    flag: Benin,
  },
  {
    name: "Burundi",
    flag: Burundi,
  },
  {
    name: "Botswana",
    flag: Botswana,
  },
  {
    name: "Burkina Faso",
    flag: Burkina,
  },
  {
    name: "Cameroon",
    flag: Cameroon,
  },
  {
    name: "Cameroon",
    flag: Cote,
  },
  {
    name: "Country1",
    flag: Country1,
  },

  {
    name: "Somali",
    flag: Somali,
  },
  {
    name: "Chad",
    flag: Chad,
  },
  {
    name: "DRC",
    flag: DRC,
  },
  {
    name: "Egypt",
    flag: Egypt,
  },
  {
    name: "Ghana",
    flag: Ghana,
  },
  {
    name: "Guinea",
    flag: Guinea,
  },
  {
    name: "Gambia",
    flag: Gambia,
  },
  {
    name: "Equatorial",
    flag: Equatorial,
  },
  {
    name: "Gabon",
    flag: Gabon,
  },
  {
    name: "Kenya",
    flag: Kenya,
  },
  {
    name: "Liberia",
    flag: Liberia,
  },
  {
    name: "Malawi",
    flag: Malawi,
  },
  {
    name: "Mali",
    flag: Mali,
  },
  {
    name: "Niger",
    flag: Niger,
  },
  {
    name: "Morocco",
    flag: Morocco,
  },
  {
    name: "Nigeria",
    flag: Nigeria,
  },
  {
    name: "RCA",
    flag: RCA,
  },
  {
    name: "Rwanda",
    flag: Rwanda,
  },
  {
    name: "Senegal",
    flag: Senegal,
  },
  {
    name: "SouthAfrica",
    flag: SouthAfrica,
  },
  {
    name: "Sierre",
    flag: Sierre,
  },
  {
    name: "Uk",
    flag: Uk,
  },
  {
    name: "Zambia",
    flag: Zambia,
  },
  {
    name: "Zimbabwe",
    flag: Zimbabwe,
  },
  {
    name: "Uganda",
    flag: Uganda,
  },
  {
    name: "Tanzania",
    flag: Tanzania,
  },
  {
    name: "Southsudan",
    flag: Southsudan,
  },
];
