import React from "react";
import { Home } from "../presentation/pages/home";
import { Layout } from "../presentation/pages/layout";
import { About } from "../presentation/pages/about";


export let RouterComponent = [
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
//   {
//     path: "*",
//     element: (
//       <Layout>
//         <About />
//       </Layout>
//     ),
//   },
];
