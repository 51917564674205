export const OurNetwork = [
    {
      figure: "640M+",
      text: (
        <div className="text-center leading-4">
          <div>mobile money wallets and</div>{" "}
          <div>bank accounts connected.</div>
        </div>
      ),
    },
    {
      figure: "30+",
      text: (
        <div className="text-center leading-4">
          <div>African countries where</div> <div>we process payments.</div>
        </div>
      ),
    },
    {
      figure: "20+",
      text: (
        <div className="text-center leading-4">
          <div>Currencies processed</div> <div>through our APIs.</div>
        </div>
      ),
    },
  ];